import get from 'lodash/get';

const buildAppVariables = (ctx) => {
  // const cookies = new Cookies(get(ctx, 'req.headers.cookie', ''));
  const appVariables = {
    locale: getLocale(ctx),
    languageId: getLanguageId(ctx),
  };

  return appVariables;
};

const getLanguageId = (ctx) => {
  try {
    const langIdFromHeader = get(ctx, `req.headers['accept-language']`, '').split(',')[0].split('-')[0];
    const langIdFromQuery = get(ctx, 'query.languageId', null);

    return langIdFromQuery ? langIdFromQuery : langIdFromHeader ? langIdFromHeader : 'en';
  } catch (err) {
    return 'en';
  }
}

const getLocale = (ctx) => {
  try {
    return getLanguageId(ctx).concat('_IN');
  } catch (err) {
    return 'en_IN';
  }
}

export default buildAppVariables;